import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Note } from 'hudl-uniform-ui-components';
import notePreviewData from '../../../../data/previews/note.yml';
import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typeText:
        "We'll use this email address and cell phone number to send you updates related to your account.",
    };
  }

  handleTypeChange = type => {
    let typeText;
    if (type === 'information') {
      typeText =
        "We'll use this email address and cell phone number to send you updates related to your account.";
    } else if (type === 'warning') {
      typeText =
        "In order for coaches and athletes to see new comments, you'll have to re-share the playlist.";
    } else if (type === 'critical') {
      typeText =
        'We spotted an issue with this order. Check your billing info and try again.';
    } else {
      typeText =
        'Video, tunes, title—this reel is ready to go. Feel free to continue editing or publish as is.';
    }

    this.setState({
      typeText: typeText,
    });
  };

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Note"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Notifications"
          tierThree="Note"
        />
        <Paragraph>
          For information related to an entire interface, check the{' '}
          <Link href="/components/notifications/notice" isDesignCodeLink>
            notice
          </Link>
          . And if something comes up as the result of a workflow, use the{' '}
          <Link href="/components/notifications/toast" isDesignCodeLink>
            toast
          </Link>
          .
        </Paragraph>
        <PageNavigation>
          <PageNavigationLink>Size</PageNavigationLink>
          <PageNavigationLink>Type</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Size">
          <Paragraph>
            Relevant to the note’s size are the sizes of both the icon and text.
            Consider location and interface density as you choose.
          </Paragraph>
          <ComponentPreview
            name="NoteSize"
            layout="row"
            previewData={notePreviewData.sizes}
            onChange={this.handleSizeChange}>
            <Note>
              This information will only appear for verified recruiters, not the
              general public.
            </Note>
          </ComponentPreview>
        </Section>

        <Section title="Type">
          <Paragraph>
            The four note types correspond directly to our feedback icons.
          </Paragraph>
          <ComponentPreview
            name="NoteType"
            layout="row"
            previewData={notePreviewData.types}
            onChange={this.handleTypeChange}>
            <Note size="medium">{this.state.typeText}</Note>
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Small and medium notes work well on mobile phones, but reserve the
            large size for tablet views only.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Non-Dismissible</SectionSubhead>
          <Paragraph>
            Every note is either ever-present or removed by righting a wrong.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="allow them to remove context."
              img="note-dismiss-dont"
            />
            <DontDoItem
              type="do"
              text="display the note for as long as the content is relevant."
              img="note-dismiss-do"
            />
          </DontDo>

          <SectionSubhead>Relative Importance</SectionSubhead>
          <Paragraph>
            Most notes should serve as subtle hints and updates in the
            interface, tied directly to just one section.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use it to introduce an entire page or discuss a feature’s purpose."
              img="note-importance-dont"
            />
            <DontDoItem
              type="do"
              text="carefully consider how helpful the note would be."
              img="note-importance-do"
            />
          </DontDo>

          <SectionSubhead>Location</SectionSubhead>
          <Paragraph>
            Where a note lives can make all the difference in whether or not its
            seen and how effective the message is.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="present multiple notes together."
              img="note-location-dont"
            />
            <DontDoItem
              type="do"
              text="place the note right next to whatever it’s discussing."
              img="note-location-do"
            />
          </DontDo>

          <SectionSubhead>Links</SectionSubhead>
          <Paragraph>
            Feel free to hyperlink text in information and warning notes for the
            sake of providing more details. Just make sure the linked resource
            is relevant and valuable enough to distract from the current task.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="make the link mandatory or use it in place of the note itself."
              img="note-links-dont"
            />
            <DontDoItem
              type="do"
              text="frame it as supplementary to what’s mentioned in the note."
              img="note-links-do"
            />
          </DontDo>

          <SectionSubhead>Styling</SectionSubhead>
          <Paragraph>
            Like{' '}
            <Link href="/components/tooltip" isDesignCodeLink>
              tooltips
            </Link>
            , you should only bold or italicize one phrase per note—never an
            entire sentence, and only if the styling is absolutely necessary.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="style an entire note just to grab a user’s attention."
              img="note-styling-dont"
            />
            <DontDoItem
              type="do"
              text="use styling for emphasis or clarification."
              img="note-styling-do"
            />
          </DontDo>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            Each note should be <strong>no longer than 170 characters</strong>.
            If you can make it shorter, great. Any longer and you risk
            interrupting their flow.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="ramble when hyperlinked text is always an option."
              img="note-length-dont"
            />
            <DontDoItem
              type="do"
              text="get to the point with only the most relevant info."
              img="note-length-do"
            />
          </DontDo>

          <SectionSubhead>Whose Interface?</SectionSubhead>
          <Paragraph>
            <Link href="/words/writing-guidelines/your-my-neutral">
              Check these guidelines
            </Link>{' '}
            and remember “we’re” always talking to a “you”.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="write in third-person and make things weirdly impersonal."
              img="note-whoseinterface-dont"
            />
            <DontDoItem
              type="do"
              text="sound like one human talking to another."
              img="note-whoseinterface-do"
            />
          </DontDo>

          <SectionSubhead>Body Only</SectionSubhead>
          <Paragraph>
            Regardless of what purpose the note serves (message or status),
            you’ll never need a title or subtitle.
          </Paragraph>
          <Paragraph>
            <strong>Messages</strong> should be written as complete sentences
            with proper punctuation.
          </Paragraph>
          <Paragraph>
            {' '}
            <strong>Statuses</strong> should be no longer than three words, free
            of punctuation.
          </Paragraph>
          <Paragraph>Both should be sentence case.</Paragraph>
        </Section>

        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
